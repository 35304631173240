import { Button, Grid, Paper, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { ReservationFormStyles } from "./ReservationForm/ReservationFormStyles";
import { useGetLotsQuery } from "features/lots/lotSlice";
import DropdownFilter from "components/DropdownFilter";
import { TimeField } from "components/TimeField/TimeField";
import DatePicker from "components/DatePicker";
import { IconDollar, IconSearch } from "icons";
import moment from "moment";
import { useGetClientsQuery } from "features/clients/clientSlice";
import _ from "lodash";
import CustomInput from "components/CustomInput";
import { Alert } from "@mui/material";
import { reservationStatusList,reservationPickupTypeList } from "features/Reservation/reservationConsts";
import { useGetAllVendorsQuery } from "features/vendors/vendorSlice";
import PhoneInput from 'components/PhoneInput';
import Validator from 'services/validator';
import { CreatableDropdown } from "components/CreatableDropdown/CreatableDropdown";
import Authorize from "components/Authorize";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import DocumentForReservation from "./ReservationDocuments/DocumentForReservation"
import { permissionProfiles } from "components/Authorize/permissionProfiles";


const ReservationDetails = ({
    reservationDetails,
    setReservationDetails,
    search,
    isLoading = false,
    errors, setErrors,
    setHasUnsavedChanges
}) => {
    const classes = ReservationFormStyles();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    vendors = vendors || [];
    
    const [nameDoc, setNameDoc] = useState({ achDoc: null, agreementDoc: null, coiDoc: null })

    const [details, setDetails] = useState(reservationDetails)
    const [newVendor, setNewVendor] = useState(null);

    if (newVendor) { vendors = [...vendors, newVendor] }

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    clients = clients || [];


    const clientAccounts = _.flatten(clients.map(c => c.clientAccounts));
    const uploadAttachment = async (type, file) => {
        let fileBase64 = "";
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            fileBase64 = reader.result.toString().replace(/^data:(.*,)?/, '');
            reservationDetails[type] = {
                filedata: fileBase64,
                fileType: file?.type,
                fileName: file?.name
            }
            var object = nameDoc;
            object[type] = file.name;
            setNameDoc({ ...nameDoc, [type]: file.name })


        }
    };
    const deleteDoc = (type) => {
        reservationDetails[type] = null
        setNameDoc({ ...nameDoc, [type]: null })
    }
    
    const onChangeValue = (key, val) => {
        setReservationDetails({ ...reservationDetails, [key]: val });
    
        setErrors({
            ...errors,
            [key]: val ? undefined : errors[key],
            ['pickupDateInPast']: key === 'pickupDate' && val < moment().format("YYYY-MM-DD") 
                ? "The date selected is in the past" 
                : undefined
        });
        setHasUnsavedChanges(true);
    }

    // const onSearch = async () => {
    //     setReservationDetails({ ...reservationDetails, ...details });
    //     await search(details);
    // }

    const isNew = !reservationDetails?.id;

    const displayError = (field, severity) => {
        return !!errors?.[field] && <Grid xs={12} className={classes.alertWrapper}>
            <Alert severity={severity} >
                {errors?.[field]}
            </Alert>
        </Grid>
    }

    const onAddCarrier = async (val) => {
        setNewVendor({...val, id: 0})
        setReservationDetails({ ...reservationDetails, ["carrierId"]: 0, ["carrierName"]: val?.name })
    }

    return (
        <>
            <div className={classes.sectionTitle}>
                Reservation Details
            </div>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Client
                    </div>
                    <CustomInput
                        id="clientAccountId"
                        value={reservationDetails?.clientAccountId}
                        elementType="dropdown"
                        onChange={val => onChangeValue("clientAccountId", +val)}
                        values={clientAccounts}
                        showEmpty
                    />
                </Grid>
                {displayError('clientAccountId', 'error')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Status
                    </div>
                    <DropdownFilter
                        id="status"
                        value={reservationDetails?.status}
                        onSelect={(val) => onChangeValue("status", val.id)}
                        elementType="dropdown"
                        values={reservationStatusList}
                        withTypeAhead
                        shrink={reservationDetails?.status}
                    />
                </Grid>
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Estimated pickup date
                    </div>
                    <DatePicker
                        id="pickupDate"
                        value={reservationDetails?.pickupDate ? moment(reservationDetails?.pickupDate).format("YYYY-MM-DD") : null}
                        onSelect={(val) => onChangeValue("pickupDate", val ? val : null)}
                        required
                        maxDate={reservationDetails?.deliveryDate ? moment(reservationDetails?.deliveryDate).format("YYYY-MM-DD") : null}
                    />
                </Grid>
                {displayError('pickupDate', 'error')}
                {displayError('pickupDateInPast', 'warning')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Estimated pickup time
                    </div>
                    <TimeField
                        id="pickupTime"
                        onChange={(val) => {
                            console.log(moment(new Date(val)).format('HH:mm:ss'));
                            console.log(val);
                            onChangeValue("pickupTime", moment(new Date(val)).format('HH:mm:ss'))
                        }}
                        //value={reservationDetails?.deliveryTime ?? null}
                        value={reservationDetails?.pickupTime ? new Date(new Date().setHours(...(reservationDetails?.pickupTime ?? '').split(':').map(Number))) : null}
                        required
                    />
                </Grid>
                {displayError('pickupTime', 'error')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Actual pick up date
                    </div>
                    <DatePicker
                        id="actualPickUpDate"
                        value={reservationDetails?.actualPickUpDate ? moment(reservationDetails?.actualPickUpDate).format("YYYY-MM-DD") : null}
                        onSelect={(val) => onChangeValue("actualPickUpDate", val ? val : null)}
                        minDate={moment(Date.now()).format("YYYY-MM-DD")}
                    />
                </Grid>
                {displayError('actualPickUpDate', 'error')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Estimated drop off date
                    </div>
                    <DatePicker
                        id="deliveryDate"
                        value={reservationDetails?.deliveryDate ? moment(reservationDetails?.deliveryDate).format("YYYY-MM-DD") : null}
                        onSelect={(val) => onChangeValue("deliveryDate", val ? val : null)}
                        required
                        minDate={reservationDetails?.pickupDate ? moment(reservationDetails?.pickupDate).format("YYYY-MM-DD") : moment(Date.now()).format("YYYY-MM-DD")}
                    />
                </Grid>
                {displayError('deliveryDate', 'error')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Estimated drop off time
                    </div>
                    <TimeField
                        id="deliveryTime"
                        onChange={(val) => {
                            console.log(moment(new Date(val)).format('HH:mm:ss'));
                            console.log(val);
                            onChangeValue("deliveryTime", moment(new Date(val)).format('HH:mm:ss'))
                        }}
                        //value={reservationDetails?.deliveryTime ?? null}
                        value={reservationDetails?.deliveryTime ? new Date(new Date().setHours(...(reservationDetails?.deliveryTime ?? '').split(':').map(Number))) : null}
                        required
                    />
                </Grid>
                {displayError('deliveryTime', 'error')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Actual drop off date
                    </div>
                    <DatePicker
                        id="actualDropOffDate"
                        value={reservationDetails?.actualDropOffDate ? moment(reservationDetails?.actualDropOffDate).format("YYYY-MM-DD") : null}
                        onSelect={(val) => onChangeValue("actualDropOffDate", val ? val : null)}
                        minDate={reservationDetails?.actualPickUpDate ? moment(reservationDetails?.actualPickUpDate).format("YYYY-MM-DD") : moment(Date.now()).format("YYYY-MM-DD")}
                    />
                </Grid>
                {displayError('actualDropOffDate', 'error')}

                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Insurance expiry date
                    </div>
                    <DatePicker
                        id="insuranceExpiryDate"
                        value={reservationDetails?.insuranceExpiryDate ? moment(reservationDetails?.insuranceExpiryDate).format("YYYY-MM-DD") : null}
                        onSelect={(val) => onChangeValue("insuranceExpiryDate", val ? val : null)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Pickup location
                    </div>
                    <DropdownFilter
                        id="pickupLocationId"
                        value={reservationDetails?.pickupLocationId}
                        onSelect={(val) => onChangeValue("pickupLocationId", val.id)}
                        elementType="dropdown"
                        values={lots}
                        withTypeAhead
                        shrink={reservationDetails?.pickupLocationId}
                    />
                </Grid>
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Dropoff location
                    </div>
                    <DropdownFilter
                        id="deliveryLocationId"
                        value={reservationDetails?.deliveryLocationId}
                        onSelect={(val) => onChangeValue("deliveryLocationId", val.id)}
                        elementType="dropdown"
                        values={lots}
                        withTypeAhead
                        shrink={reservationDetails?.deliveryLocationId}
                    />
                </Grid>
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Pickup type
                    </div>
                    <DropdownFilter
                        id="pickupType"
                        value={reservationDetails?.pickupType}
                        onSelect={(val) => onChangeValue("pickupType", val.id)}
                        elementType="dropdown"
                        values={reservationPickupTypeList}
                        withTypeAhead
                        shrink={reservationDetails?.pickupType}
                    />
                </Grid>

                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Carrier
                    </div>
                    <CreatableDropdown
                        options={vendors}
                        value={reservationDetails?.carrierId}
                        addValue={onAddCarrier}
                        onChange={(val) => onChangeValue("carrierId", val)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        POC name
                    </div>
                    <CustomInput
                        id="pocName"
                        elementType='input'
                        value={reservationDetails?.pocName}
                        onChange={(val) => onChangeValue("pocName", val)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        POC email
                    </div>
                    <CustomInput
                        id="pocEmail"
                        elementType='input'
                        value={reservationDetails?.pocEmail}
                        valid={Validator.validateEmail(reservationDetails?.pocEmail)}
                        touched={reservationDetails?.pocEmail}
                        onChange={(val) => onChangeValue("pocEmail", val)}
                    />
                </Grid>
                {displayError('pocEmail', 'error')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        POC phone number
                    </div>
                    <PhoneInput
                        value={reservationDetails?.pocPhone}
                        valid={reservationDetails?.pocPhone && Validator.validatePhone(reservationDetails?.pocPhone)}
                        onChange={(val) => onChangeValue("pocPhone", val)} />
                </Grid>
                {displayError('pocPhone', 'error')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Rental rate
                    </div>
                        <CustomInput
                            value={reservationDetails?.rentalRate}
                            onChange={(val) => onChangeValue("rentalRate", val == "" ? null : val)}
                            icon={<IconDollar />}
                            elementType="input"
                            numberOnly
                        />
                </Grid>
                {isNew ? <>
                    <Grid item xs={12} className={classes.field}>
                        <DocumentForReservation onSave={(file) => uploadAttachment("coiDoc", file)} typeName="Coi" selectFile={nameDoc?.coiDoc ? true : false}></DocumentForReservation>
                        {nameDoc?.coiDoc && <Grid container alignItems="center" justifyContent="flex-start"><Grid item>{nameDoc?.coiDoc}  </Grid>
                            <IconButton item
                                title={'Delete image'}
                                edge='end'
                                aria-label='delete'
                                className={{ ...classes.imageDeleteIcon }}
                                onClick={() => { deleteDoc("coiDoc") }}>
                                <DeleteIcon />
                            </IconButton></Grid>}
                    </Grid>
                    <Authorize profile={permissionProfiles.RESERVATIONS.VIEW_BANKING_INFO}
                readonlyComponent={<DocumentForReservation
                            onSave={(file) => uploadAttachment("achDoc", file)}
                            typeName="ACH Authorization Form"
                            permissionViewEditFile={false}
                            >
                        </DocumentForReservation>}>
                 <Grid item xs={12} className={classes.field}>
                        <DocumentForReservation
                            onSave={(file) => uploadAttachment("achDoc", file)}
                            typeName="ACH Authorization Form"
                            selectFile={nameDoc?.achDoc ? true : false} >
                        </DocumentForReservation>
                        {nameDoc?.achDoc && <Grid container alignItems="center" justifyContent="flex-start"><Grid item>{nameDoc?.achDoc}  </Grid>
                            <IconButton item
                                title={'Delete image'}
                                edge='end'
                                aria-label='delete'
                                className={{ ...classes.imageDeleteIcon }}
                                onClick={() => { deleteDoc("achDoc") }}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>}
                    </Grid>
            </Authorize>
                    <Grid item xs={12} className={classes.field}>
                        <DocumentForReservation onSave={(file) => uploadAttachment("agreementDoc", file)} typeName="Rental Agreement" selectFile={nameDoc?.agreementDoc ? true : false}></DocumentForReservation>
                        {nameDoc?.agreementDoc && <Grid container alignItems="center" justifyContent="flex-start">  <Grid item>{nameDoc?.agreementDoc}  </Grid>
                            <IconButton item
                                title={'Delete image'}
                                edge='end'
                                aria-label='delete'
                                className={{ ...classes.imageDeleteIcon }}
                                onClick={() => { deleteDoc("agreementDoc") }}>
                                <DeleteIcon />
                            </IconButton></Grid>}
                    </Grid>
                  
                   
                </> : <></>}
            </Grid>
        </>
    )
};

export default ReservationDetails;
