import { Select } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from "react";
import NotificationProvider from "../../../../../components/NotificationProvider";
import { InvoiceStatusList } from "../../loadInvoiceConsts";
import { useUpdateInvoiceStatusMutation } from "../../loadInvoiceSlice";
import { invoiceStatusDropdownStyle } from "./InvoiceStatusDropdownStyle";

const InvoiceStatusDropdown = ({ invoice, onChange, filled = true, isDisabled = false }) => {
    const classes = invoiceStatusDropdownStyle();

    let [updateStatus, { isLoading: isLoadingUpdateStatus, isSuccess: updateStatusSucces }] = useUpdateInvoiceStatusMutation();

    const onStatusChange = async (event) => {
        let prevStatus = invoice.statusId;
        invoice.statusId = event.target.value;
        const result = await updateStatus({
            id: invoice.id,
            statusId: event.target.value,
        });
        if (result?.error) {
            invoice.statusId = prevStatus;
            NotificationProvider.error(`Failed to update status`)
        } else {
            NotificationProvider.success(`Successfully updated status`);
        }
    }

    const renderValue = (selected) => {
        const color = InvoiceStatusList?.find(s => s.id == selected)?.color;
        return <div className={`${classes.selectItem} ${filled ? 'filled' : ''}`} style={filled ? { backgroundColor: color } : { color }}>
            {InvoiceStatusList?.find(s => s.id == selected)?.name}
        </div>
    };

    return (
        <Select
            native={false}
            className={classes.select}
            value={invoice?.statusId}
            onChange={onStatusChange}
            variant="outlined"
            inputProps={{
                className: classes.select,
            }}
            MenuProps={{
                className: classes.selectMenu,
                style: { padding: 0 }
            }}
            IconComponent={ExpandMoreIcon}
            renderValue={renderValue}
            disabled={isDisabled}
        >
            {InvoiceStatusList?.map((value, key) => (
                <div
                    className={classes.statusItem}
                    //style={{ backgroundColor: filled && value.color, color: !filled && value.color }}
                    style={{ color: value.color }}
                    id={key} key={key} value={value.id}>
                    {value.name}
                </div>
            ))}
        </Select>
    )
}
export default InvoiceStatusDropdown;