import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Authorize from "../../../../../components/Authorize";
import { isAuthorized } from "../../../../../components/Authorize/authorizeUtil";
import { permissionProfiles } from "../../../../../components/Authorize/permissionProfiles";
import DataListHeader from "../../../../../components/DataListHeader";
import DataTable from "../../../../../components/DataTable/DataTable";
import EmptyPlaceholder from "../../../../../components/EmptyPlaceholder/EmptyPlaceholder";
import ExportButton from "../../../../../components/ExportButton/ExportButton";
import PageComponent from "../../../../../components/Page/PageComponent";
import useSortOptions from "../../../../../hooks/useSortOptions";
import FilterService from "../../../../../services/filterService";
import { useGetClientsQuery } from "../../../../clients/clientSlice";
import { useGetLoadsQuery } from "../../../../loads/loadsSlice";
import { useGetLotsQuery } from "../../../../lots/lotSlice";
import { InvoiceTypes } from '../../../invoiceConsts.ts';
import InvoiceScreenSelector from '../../../shared/InvoiceScreenSelector';
import { InvoiceStatusList, InvoiceStatusMap, InvoiceStatuses, loadInvoiceColumns } from "../../loadInvoiceConsts";
import {
    setFilters,
    setSearchVal,
    useGetInvoiceCustomerQuery,
    useGetInvoiceVendorsQuery,
    useGetInvoicesQuery
} from "../../loadInvoiceSlice";
import AddInvoice from '../AddInvoice/AddInvoice';
import InvoiceDetails from "../InvoiceDetails/InvoiceDetails";
import InvoiceStatusDropdown from "../InvoiceStatusDropdown";
import { InvoicePageStyle } from './InvoicePageStyle';

const InvoicePage = ({loadId}) => {
    const classes = InvoicePageStyle();

    const dispatch = useDispatch();
    const {filters, searchVal} = useSelector(state => state.loadInvoiceState);

    let {data: invoices, error, isFetching} = useGetInvoicesQuery(null);
    let {data: clients, error: clientError, isLoading: isLoadingClients} = useGetClientsQuery();
    let {data: lots, error: lotError, isLoading: isLoadingLots} = useGetLotsQuery();
    let {data: loads, error: loadError, isLoading: isLoadingLoads} = useGetLoadsQuery({lmStatus: null})
    let {data: vendors, error: vendorError, isLoading: isLoadingVendor} = useGetInvoiceVendorsQuery({});
    let {data: customers, error: customerError, isLoading: isLoadingCustomer} = useGetInvoiceCustomerQuery({});

    const hasApproveAndDeleteInvoicePermission = isAuthorized(permissionProfiles.INVOICE.APPROVE_DELETE_TRANSPORTATION_INVOICE);
    const hasEditTransportationInvoice = isAuthorized(permissionProfiles.INVOICE.EDIT_TRANSPORTATION_INVOICE);


    const loadsLookup = loads?.reduce(function (map, obj) {
        map[obj.lmId] = obj;
        return map;
    }, {})

    const [invoiceDetails, setInvoiceDetails] = useState();
    const [showComplete, setShowComplete] = useState(false);
    const [openNewInvoice, setOpenNewInvoice] = useState(false);

    const invoiceFormattedData = invoices?.filter(invoice => showComplete || (invoice.statusId !== InvoiceStatuses.REJECTED
        && invoice.statusId !== InvoiceStatuses.SENDQUICKBOOKS
        && invoice.statusId !== InvoiceStatuses.APPROVED))?.map(invoice => {
        let load = loadsLookup?.[invoice?.externalId];
        const pickupLot = lots?.find(c => c.id === load?.pickupLocationId);
        const deliveryLot = lots?.find(c => c.id === load?.deliveryLocationId);
        return {
            ...invoice,
            vendors: vendors,
            customers: customers,
            vendorName: vendors?.find(c => c.id === invoice.quickBooksVendorId)?.displayName,
            customerName: customers?.find(c => c.id === invoice.quickBooksCustomerId)?.fullyQualifiedName,
            clientName: clients?.find(c => c.id === invoice.clientId)?.name,
            pickupLotName: pickupLot ? `${pickupLot?.city}, ${pickupLot?.state}` : '-',
            deliveryLotName: deliveryLot ? `${deliveryLot?.city}, ${deliveryLot?.state}` : '-',
            miles: load?.miles,
            statusName: InvoiceStatusMap[invoice.statusId]?.name,
            readonly: invoice.statusId === InvoiceStatuses.APPROVED
                || invoice.statusId === InvoiceStatuses.SENDQUICKBOOKS
        }
    }) ?? [];



    useEffect(() => {
        if (invoiceDetails && !isFetching) {
            setInvoiceDetails(invoiceFormattedData?.find(row => row.id == invoiceDetails?.id))
        }
    }, [isFetching])

    const rowActions = (rowId) => ([
        {
            label: 'Details',
            component: <Authorize profile={permissionProfiles.INVOICE.EDIT_TRANSPORTATION_INVOICE}>
                <Button onClick={() => setInvoiceDetails(invoiceFormattedData?.find(row => row.id == rowId))}>
                    Details
                </Button>
            </Authorize>,
        }
    ])

    const filterConfig = [
        {type: 'text', size: 3, title: 'Client', key: 'clientId', values: clients},
        {type: 'dateRange', size: 3, title: 'From', key: 'issueDate', values: []},
    ];

    if (isAuthorized(permissionProfiles.INVOICE.APPROVE_DELETE_TRANSPORTATION_INVOICE)) {
        filterConfig.push({type: 'text', size: 3, title: 'Status', key: 'status', values: InvoiceStatusList}) //todo statusid
    }

    let filteredResults = FilterService.filter(filters, searchVal, invoiceFormattedData ?? [], filterConfig);

    const onDropFilter = (filterValue, prop) => {
        dispatch(setFilters({...filters, ...{[prop]: {value: filterValue}}}));
    };

    const searchVehicles = value => {
        dispatch(setSearchVal(value.trim()));
    }

    const columns = [
        ...loadInvoiceColumns,
        {
            name: "Status", key: "statusName", exportKey: "statusName", width: 200, selectedByDefault: true,
            component: (row) => <InvoiceStatusDropdown
                isDisabled={!hasApproveAndDeleteInvoicePermission}
                filled={false}
                invoice={invoiceFormattedData?.find(invoice => invoice.id == row?.id)}/>
        }]
    const mapExportData = row => {
        const invoiceData = {};
        columns.map(col => {
            invoiceData[col.name] = row[col.exportKey ?? col.key]
        })
        return invoiceData;
    };

    const [sortOptions, sortData] = useSortOptions();

    return (
        <PageComponent
            loading={isFetching}
            header={
                <DataListHeader
                    titleSize={6}
                    titleComponent={(
                        <InvoiceScreenSelector
                            selected={InvoiceTypes.TRANSPORTS}
                        />
                    )}
                    data={filteredResults}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    onSearch={searchVehicles}
                    actionButtons={<Grid container spacing={1}>
                        <Grid item>
                            <FormControlLabel
                                control={<Checkbox checked={showComplete}
                                                   onChange={(e, value) => setShowComplete(value)}/>}
                                label="Include History"/>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color="primary" onClick={() => setOpenNewInvoice(true)}>
                                Create Invoice
                            </Button>
                        </Grid>
                        <Grid item>
                            <ExportButton fileLabel="Export" data={filteredResults} mapExportData={mapExportData} className='' />
                        </Grid>
                    </Grid>}
                />}>
            {!!invoices?.length ? <DataTable
                    columns={columns}
                    actions={rowActions}
                    maxActionCount={1}
                    rows={orderBy(
                        filteredResults,
                        sortOptions.columnToSort,
                        sortOptions.sortDirection
                    )}
                    rowIdentifier='id'
                    onSort={sortData}
                    sortDirection={sortOptions.sortDirection}
                    columnToSort={sortOptions.columnToSort}
                    allowHorizontalScroll={false}
                /> :
                <EmptyPlaceholder text={'No Invoices'}/>}
            <InvoiceDetails invoice={invoiceDetails} onClose={() => setInvoiceDetails()}
                            isReadOnlyMode={!hasEditTransportationInvoice}
                            IsStatusReadOnly={!hasApproveAndDeleteInvoicePermission}/>
            <AddInvoice open={openNewInvoice} setOpen={setOpenNewInvoice} onClose={() => setOpenNewInvoice(false)}/>
        </PageComponent>
    )
}
export default InvoicePage;